<template>
  <div>
    <div class="box has-text-centered content" v-if="!hasUnsubscribed">
      <h4 class="title">Unsubscribe</h4>
      <p class="mb-2">
        If you would like to unsubscribe from all Covered2go emails,
      </p>
      <p class="my-4">please confirm by clicking the button below.</p>
      <button class="button is-primary" @click="unsubscribe">
        Unsubscribe
      </button>
    </div>
    <div class="box has-text-centered content" v-if="hasUnsubscribed">
      <h4 class="title">
        We confirm your request to unsubscribe has been received.
      </h4>
      <p class="mb-2">
        Please allow time for our system to process your request.
      </p>
      <p class="mb-2">Thank you for your patience.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicyRedirect',
  props: ['id'],
  data() {
    return {
      hasUnsubscribed: false,
    };
  },
  mixins: [],
  methods: {
    unsubscribe() {
			this.hasUnsubscribed = true;
      if (this.id.includes('@')) {
				this.$http
          .get('unsubscribe_by_email/' + this.id)
          .then(() => {
            this.hasUnsubscribed = true;
          })
          .catch((err) => console.log(err));
      } else {
        this.$http
          .get('unsubscribe/' + this.id)
          .then(() => {
            this.hasUnsubscribed = true;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style scoped></style>
